import React, {useContext, useState, useEffect} from "react";
import { Button, InlineLoading, Modal } from "carbon-components-react";
import { navigate, graphql } from "gatsby";
import { SubmitButtonComponent, Entity, UserContext} from '@parallelpublicworks/entitysync';
import AnswersReview from '../components/AnswersReview';
import ProgressBar from '../components/ProgressBar';
import Deadline from '../components/Deadline';
import {NssspContext} from '../gatsby-theme-carbon/components/Layout';
import { ArrowLeft16 } from '@carbon/icons-react';
import {InlineNotification} from 'gatsby-theme-carbon';
import submitNValidateApplication from "../util/submitNValidateApplication";

const SubmitButton = SubmitButtonComponent(({onClick, disabled, buttonLabel, kind = "primary", ...props}) => {
  return <Button onClick={(e) => onClick(e)} kind={kind} disabled={disabled}>{buttonLabel}</Button>
});



function Review({ data, location }) {
    const nsssp = useContext(NssspContext);
    const [source, setSource] = useState(null);
    const [submissionErrors, setSubmissionErrors] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const userContext = useContext(UserContext)

    const { answers = {}, fetchUserAnswers, loadingAnswers } = nsssp;

    useEffect(() => {
      if(!source && nsssp?.answers?.answers?.id && typeof nsssp?.answers?.answers?.id === 'string' && nsssp?.answers?.answers?.id.length > 0){
          setSource({ id: nsssp.answers.answers.id, type: 'node--answers'})
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsssp])

    useEffect(() => {
      let is_submitted = nsssp?.answers?.submitted;
      if(is_submitted){
        if(typeof window !== 'undefined'){
          window.scrollTo({
            top: 340,
            left: 0,
            behavior: 'smooth'
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsssp?.answers])

    if(nsssp?.user?.id && (!loadingAnswers && !nsssp?.answers?.answers?.attributes)) {
      navigate('/application');
      return <></>
    }

    if(!source && loadingAnswers){
      return  <InlineLoading description="Loading..." status='active' />
    }

    const onSubmit = async (e, unsavedChanges, entityData) => {
      let go = typeof window !== 'undefined' ? window.confirm("Are you sure you want to submit your application? You won't be able to make any changes afterwards.") : true;
      if(unsavedChanges && go){
        if (!unsavedChanges.attributes) unsavedChanges.attributes = {}
        unsavedChanges.attributes.field_submitted = true;
      }else{
        e.preventDefault();
      }
    }

    const submitAndValidate = async (e) => {
      e.preventDefault();

      setSubmitting(true);

      try {

        let drupalId = nsssp?.answers.answers.attributes.drupal_internal__nid;
        let response = await submitNValidateApplication(drupalId, userContext);
        
        if(response && response.data?.submitted == false) {
          setSubmissionErrors(response.data?.errors);
        } else if(response && response.data.submitted == true) {
          fetchUserAnswers();
          window.scrollTo({
            top: 340,
            left: 0,
            behavior: 'smooth'
          });
        }

        setSubmitting(false);

      } catch(e) {
        setSubmitting(false);
      }
    }
    return (
        <div className={`application-wrapper review ${nsssp?.answers?.all_done ? 'complete' : ''}`}>
            <div className="bx--row">
                <div id="left-panel" className="bx--col-lg-4">
                  <div>
                    <Button kind="tertiary" onClick={(e) => navigate('/application')}>
                      <ArrowLeft16 /> <span>Back to application</span>
                    </Button>
                    <ProgressBar />
                    <Deadline />
                  </div>
                </div>
                <div id="right-panel" className="bx--col-lg-8">
                    <div>
                        <div id="application-title" className="padd">
                            <h2>Your Answers</h2>
                        </div>
                        {answers?.submitted && <div id="task-description" className="padd txt"><InlineNotification kind="success"><p>Thank you! We've received your application.</p></InlineNotification></div>}
                        {data.allNodeTask.edges.map(({node}) => {
                            return (
                                <AnswersReview task={node} />
                            );
                        })}
                        {source !== null && <div className="button-group">
                            {/* <Entity type="node--answers" source={source} componentId="task-entity">
                              <SubmitButton onSubmit={onSubmit} kind="primary" disabled={locked || submitted} buttonLabel={`${submitted ? 'Application Sent' : 'Submit Application'}`}  />
                            </Entity> */}
                            {
                              submitting ?
                              <div style={{ display: "flex", padding: "1rem" }}><InlineLoading style={{ marginRight: "auto"}} status="active" iconDescription="Loading" description="Validating application..." /></div> :
                              <Button lo type="button" kind="primary" onClick={submitAndValidate} disabled={answers?.submitted || !nsssp?.answers?.all_done}>{`${answers?.submitted ? 'Application Sent' : 'Submit Application'}`}</Button>
                            }
                        </div>}
                    </div>
                </div>
            </div>
            <FeedBackModal errors={submissionErrors} onRequestClose={()=>setSubmissionErrors([])} />
        </div>
    )
}

export default Review;

export const query = graphql`
    query {
        allNodeTask(sort: {fields: title, order: ASC}) {
            edges {
                node {
                    id
                    drupal_id
                    title
                    field_description {
                      value
                    }
                    internal {
                      type
                    }
                    relationships {
                      field_questions {
                        ... on node__text_question {
                          drupal_id
                          title
                          field_is_required
                          field_identifier
                          field_type
                          field_number_type
                          internal {
                            type
                          }
                        }
                        ... on node__checkbox_question {
                          drupal_id
                          title
                          field_is_multiple
                          field_is_required
                          field_identifier
                          internal {
                            type
                          }
                          relationships {
                            field_options {
                              drupal_id
                              title
                            }
                            field_invalid_options {
                              drupal_id
                            }
                          }
                        }
                        ... on node__address_question {
                          drupal_id
                          field_identifier
                          field_is_required
                          field_exclude_fields
                          title
                          internal {
                            type
                          }
                        }
                        ... on node__file_question {
                          drupal_id
                          title
                          field_is_required
                          field_identifier
                          internal {
                            type
                          }
                        }
                        ... on node__lor_question {
                          drupal_id
                          title
                          field_number_of_letters 
                          internal {
                            type
                          }
                        }
                      }
                    } 
                }
            }
        }
    }
`
const FeedBackModal = ({errors, onRequestClose}) => {

  return (
          <Modal open={errors && errors.length > 0} onRequestClose={onRequestClose} onRequestSubmit={onRequestClose} primaryButtonText="Close">
          <h3>You have some errors on your submission</h3>
            {
              errors.map((error, index) => {
                let taskTitle = Object.keys(error)[0];
                
                return (
                  <div key={`${taskTitle}${index}`}>
                    <h4>{taskTitle}</h4>
                    <ul>
                      {
                        error[taskTitle].map(detail=><li key={detail.message}>{`${detail.title ? detail.title + ": " : ""}${detail.message}`}</li>)
                      }
                    </ul>
                  </div>
                )
              })
            }
          </Modal>
  );
}