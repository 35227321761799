import React, {useContext} from 'react'
import {NssspContext} from '../gatsby-theme-carbon/components/Layout';

function ProgressBar() {
    const nsssp = useContext(NssspContext);
    return (
        <div id="progress-wrapper" className="padd">
            {nsssp?.answers?.completed_count !== nsssp?.tasks.length && <p>{`You have ${nsssp?.answers?.completed_count ? nsssp?.answers?.completed_count : 0} of ${nsssp?.tasks.length} tasks completed`}</p>}
            {nsssp?.answers?.completed_count === nsssp?.tasks.length && <p>{`You have completed all tasks`}</p>}
            <progress id="progress" value={nsssp?.answers?.percentage} max="100" color="#4b2e83"></progress>
        </div>
    )
}

export default ProgressBar
